<template>
  <div>
    <VsPayment></VsPayment>
  </div>
</template>
<script>
import VsPayment from "@/views/commons/components/reports/tabs/vs-payment/VsPayment.vue";

export default {
  components: {
    VsPayment,
  },
};
</script>